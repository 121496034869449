import { createSlice } from '@reduxjs/toolkit';
import { getAnonymousUnit, handleError, parseUnitParams } from '@homeplay/api';
import { formatBalance } from '@homeplay/utils';

const initialState = {
  id: null,
  token: null,
  balance: 0,
  bonus: 0,
  depositBalance: 0,
  showAcoinLink: false,
  cashoutAtmEnabled: false,
  cashoutEftEnabled: false,
  cashoutTicketEnabled: false,
  cashoutAtmMinAmount: 20000,
  cashoutEftMinAmount: 20000,
  cashoutAtmFee: 0,
  cashoutEftFee: 0,
  cashoutFreeRequestsNum: 0,
  cashoutRequestFeePeriod: 0,
  products: null,
  greenDreamGames: null,
  club888Games: null,
  playnGoGames: null,
  visoGames: null,
  netEntGames: null,
  onlyPlayGames: null,
  ptcGames: null,
  olnGames: null,
  topOlnGames: null,
  ppLiveCasinoGames: null,
  topGames: null,
  bonusGames: null,
  error: null,
  loading: false,
  loadingMessage: null,
  supportParams: null,
};

export const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    loadUnitStart: (state, action) => {
      state.error = null;
      state.loading = true;
      if (action.payload && action.payload.loadingMessage) {
        state.loadingMessage = action.payload.loadingMessage;
      }
    },
    loadUnitSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      error: null,
      loading: false,
      loadingMessage: null,
    }),
    loadUnitFail: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.loadingMessage = null;
    },
    setUnitToken: (state, action) => {
      state.token = action.payload.token;
      state.loading = true;
      if (action.payload && action.payload.loadingMessage) {
        state.loadingMessage = action.payload.loadingMessage;
      }
    },
    resetUnitError: (state) => {
      state.error = null;
    },
    disableUnitLoading: (state) => {
      state.loading = false;
      state.loadingMessage = null;
    },
    cashOperationStart: (state, action) => {
      state.error = null;
      state.loading = true;
      if (action.payload && action.payload.loadingMessage) {
        state.loadingMessage = action.payload.loadingMessage;
      }
    },
    cashOperationSuccess: (state, action) => {
      state.balance = action.payload.balance;
      state.depositBalance = action.payload.depositBalance;
      state.error = null;
      state.loading = false;
      state.loadingMessage = null;
    },
    cashOperationFail: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.loadingMessage = null;
    },
    updateUnit: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setUnitError: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      state.loadingMessage = null;
    },
  },
});

export const {
  loadUnitStart,
  loadUnitSuccess,
  loadUnitFail,
  setUnitToken,
  resetUnitError,
  disableUnitLoading,
  cashOperationStart,
  cashOperationSuccess,
  cashOperationFail,
  updateUnit,
  setUnitError,
} = unitSlice.actions;

export const setUnitParams = (params) => async (dispatch) => {
  try {
    const unitParams = parseUnitParams(params);
    dispatch(loadUnitSuccess({ ...unitParams }));
  } catch (error) {
    dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
  }
};

export const updateUnitParams = (params) => async (dispatch) => {
  try {
    const unitParams = parseUnitParams(params);
    dispatch(updateUnit({ ...unitParams }));
  } catch (error) {
    dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
  }
};

export const setAnonymousUnit = (siteName, isLandBased) => async (dispatch) => {
  dispatch(loadUnitStart());
  try {
    const unitToken = await getAnonymousUnit(siteName, isLandBased);
    if (unitToken) {
      dispatch(setUnitToken({ token: unitToken }));
      localStorage.setItem(siteName ? siteName : 'hp-token', unitToken);
    }
  } catch (error) {
    dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
  }
};

export const selectUnitId = (state) => state.unit.id;
export const selectBalance = (state) => state.unit.balance;
export const selectShowAcoinLink = (state) => state.unit.showAcoinLink;
export const selectProducts = (state) => state.unit.products;
export const selectTopGames = (state) => state.unit.topGames;
export const selectClub888Games = (state) => state.unit.club888Games;
export const selectGreenDreamGames = (state) => state.unit.greenDreamGames;
export const selectPlaynGoGames = (state) => state.unit.playnGoGames;
export const selectVisoGames = (state) => state.unit.visoGames;
export const selectNetEntGames = (state) => state.unit.netEntGames;
export const selectOnlyPlayGames = (state) => state.unit.onlyPlayGames;
export const selectPtcGames = (state) => state.unit.ptcGames;
export const selectOlnGames = (state) => state.unit.olnGames;
export const selectTopOlnGames = (state) => state.unit.topOlnGames;
export const selectPPLiveCasionGames = (state) => state.unit.ppLiveCasinoGames;
export const selectBonusGames = (state) => state.unit.bonusGames;
export const selectUnitToken = (state) => state.unit.token;
export const selectSupportParams = (state) => state.unit.supportParams;
export const selectCashoutParams = (state) => ({
  user: state.auth.user,
  unitToken: state.unit.token,
  balance: state.unit.balance,
  depositBalance: state.unit.depositBalance,
  cashoutAtmMinAmount: state.unit.cashoutAtmMinAmount,
  cashoutEftMinAmount: state.unit.cashoutEftMinAmount,
  cashoutAtmFee: state.unit.cashoutAtmFee,
  cashoutEftFee: state.unit.cashoutEftFee,
  cashoutFreeRequestsNum: state.unit.cashoutFreeRequestsNum,
  cashoutRequestFeePeriod: state.unit.cashoutRequestFeePeriod,
  cashoutAtmEnabled: state.unit.cashoutAtmEnabled,
  cashoutEftEnabled: state.unit.cashoutEftEnabled,
  cashoutTicketEnabled: state.unit.cashoutTicketEnabled,
});
export const selectWithdrawalLimits = (state) => ({
  unverifiedSingleLimit: formatBalance(
    state.unit.secondLevelVerificationThreshold
  ),
  unverifiedMonthlyLimit: formatBalance(
    state.unit.monthlySecondLevelVerificationThreshold
  ),
  standardSingleLimit: formatBalance(
    state.unit.thirdLevelVerificationThreshold
  ),
  standardMonthlyLimit: formatBalance(
    state.unit.monthlyThirdLevelVerificationThreshold
  ),
});
export const selectIsVerificationEnabled = (state) =>
  state.unit.secondLevelVerificationThreshold &&
  state.unit.monthlySecondLevelVerificationThreshold &&
  state.unit.thirdLevelVerificationThreshold &&
  state.unit.monthlyThirdLevelVerificationThreshold;

export default unitSlice.reducer;
