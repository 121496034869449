import Cron from 'cron-converter';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatBalance = (credits, coins = true) => {
  if (!credits) {
    return coins ? 'R0.00' : 'R0';
  }

  const creditParts = Math.abs(credits / 100)
    .toString()
    .split('.');
  creditParts[0] = creditParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (creditParts.length > 1) {
    if (creditParts[1].length < 2) {
      creditParts[1] += '0';
    }
  } else {
    creditParts.push('00');
  }

  let balance = 'R' + creditParts.join('.');

  if (!coins) {
    balance = balance.replaceAll('.00', '');
  }

  if (credits < 0) {
    return '-' + balance;
  }

  return balance;
};

export const formatToken = (tokenCode) => {
  return tokenCode.replace(/(.{4})/g, '$1 ').trim();
};

export const formatFeePeriod = (period) => {
  let minutes = Math.floor(period / 60);
  const days = Math.floor(minutes / 1440);
  minutes -= days * 1440;
  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  let text = '';

  if (days === 1 && hours === 0 && minutes === 0) {
    text += ` 24 hours`;
  } else {
    if (days > 0) {
      if (days > 1) {
        text += ` ${days} days`;
      } else {
        text += ' day';
      }
    }

    if (hours > 0) {
      if (hours > 1) {
        text += ` ${hours} hours`;
      } else {
        text += ' hour';
      }
    }

    if (minutes > 0) {
      if (minutes > 1) {
        text += ` ${minutes} minutes`;
      } else {
        text += ' minute';
      }
    }
  }

  return text;
};

export const getAllowedBalance = (balance, minValue) => {
  if (balance > process.env.REACT_APP_WITHDRAWAL_MAX_VALUE) {
    return +process.env.REACT_APP_WITHDRAWAL_MAX_VALUE;
  } else if (balance < minValue) {
    return 0;
  }
  return Math.floor(balance / 1000) * 1000;
};

export const setMaskForPhone = (phoneNumber) => {
  const phone = deleteGaps(phoneNumber);
  let formattedPhone = phone.slice(0, 3);
  let index = 3;

  if (phone.slice(0, 2) === '27') {
    formattedPhone = phone.slice(0, 2);
    index = 2;
    if (phone[2] === '0') {
      formattedPhone += ' ' + phone.slice(2, 5);
      index = 5;
    } else {
      formattedPhone += ' ' + phone.slice(2, 4);
      index = 4;
    }
  } else if (phone[0] === '+' && phone.length > 3) {
    if (phone[3] === '0') {
      formattedPhone += ' ' + phone.slice(3, 6);
      index = 6;
    } else {
      formattedPhone += ' ' + phone.slice(3, 5);
      index = 5;
    }
  }

  if (phone.length > index) {
    formattedPhone += ' ' + phone.slice(index, index + 3);
  }
  if (phone.length > index + 3) {
    formattedPhone += ' ' + phone.slice(index + 3);
  }
  return formattedPhone;
};

export const deleteGaps = (value) => {
  return value.replace(/\s/g, '');
};

export const getValidPhoneNumber = (phoneNumber) => {
  const phone = phoneNumber.replace(/^((\+?27)|(00))/, '');
  const pattern = /^[\d\(\)\-\s]{8,}$/;
  if (!pattern.test(phone)) {
    return null;
  }

  const phoneAfrican = parsePhoneNumberFromString(phone, 'ZA');
  if (phoneAfrican && phoneAfrican.isValid()) {
    return phoneAfrican.number;
  } else {
    const phoneInternational = parsePhoneNumberFromString(phone);
    if (phoneInternational && phoneInternational.isValid()) {
      return phoneInternational.number;
    }
  }
  return null;
};

export const shortPhone = (phoneNumber) => {
  const phone = deleteGaps(phoneNumber);
  return phone.slice(0, 3) + ' *** ' + phone.slice(-4);
};

export const filterGames = (gamesList, filterList) => {
  return gamesList.filter((game) => filterList.includes(game.slug));
};

export const getPromoTexts = (promotion) => {
  const promoDates = getCronWeeklySchedule(promotion.schedule);

  if (!promoDates.length || !isPromoUniform(promoDates)) {
    return null;
  }

  const promoTimeText = getPromoTimeText(promoDates, promotion.duration);
  const promoDaysText = getPromoDaysText(promoDates);

  if (!promoTimeText || !promoDaysText) {
    return null;
  }

  return {
    time: promoTimeText,
    days: promoDaysText,
  };
};

export const getCronSchedule = (cronString, startDate) => {
  const cronInstance = new Cron({ timezone: 'UTC' });

  cronInstance.fromString(cronString);
  return cronInstance.schedule(startDate);
};

const getPromoTimesString = (promoTimes) => {
  return promoTimes.reduce(
    (promoTimes, time) => promoTimes + time.hour() + '' + time.minute(),
    ''
  );
};

const getPromoDaysText = (promoDates) => {
  let daysText = '';
  switch (promoDates.length) {
    case 7:
      daysText += 'daily';
      break;

    case 1:
      daysText += `every ^${promoDates[0].day}^`;
      break;

    case 2:
      daysText += `every ^${promoDates[0].day}^ and ^${promoDates[1].day}^`;
      break;

    default:
      let days = '';
      let isDaysContinuity = true;
      let prevDay = promoDates[0].times[0].day();
      promoDates.forEach((promo, index) => {
        const currentDay = promo.times[0].day();
        if (currentDay - prevDay > 1) {
          isDaysContinuity = false;
        }
        prevDay = currentDay;
        if (index === promoDates.length - 1) {
          days += `and ^${promo.day}^`;
        } else {
          days += `^${promo.day}^${
            index === promoDates.length - 2 ? '' : ','
          } `;
        }
      });

      if (isDaysContinuity) {
        daysText += `#every ^${promoDates[0].day} - ${
          promoDates[promoDates.length - 1].day
        }^`;
      } else {
        daysText += '#every ' + days;
      }
  }

  return daysText;
};

const getPromoTimeText = (promoDates, promoDuration) => {
  let timeText = '';
  if (promoDates[0].times.length < 4) {
    timeText += 'between ';
    promoDates[0].times.forEach((time, index) => {
      if (index !== 0) {
        timeText += 'or ';
      }
      timeText += `^${formatMomentTime(time)}^ and ^${formatMomentTime(
        time.add(promoDuration, 'ms')
      )}^ `;
    });
  } else {
    const delta = promoDates[0].times[1] - promoDates[0].times[0];
    if (delta % 3600000 === 0) {
      const hoursCount = delta / 3600000;
      timeText += `every ^${hoursCount}^ hour${hoursCount > 1 ? 's' : ''} `;
    } else {
      timeText += `every ^${delta / 60000}^ minutes `;
    }
  }

  return timeText;
};

const formatMomentTime = (moment) => {
  let pattern = 'h a';
  if (moment.minute()) {
    pattern = 'h:m a';
  }
  return moment.format(pattern);
};

const formatMomentDay = (moment) => {
  return moment.format('ddd').toUpperCase();
};

const isPromoUniform = (promoDates) => {
  if (promoDates.length === 1) {
    return true;
  }

  const promoTimes = getPromoTimesString(promoDates[0].times);

  promoDates.forEach((promo) => {
    if (getPromoTimesString(promo.times) !== promoTimes) {
      return false;
    }
  });

  return true;
};

const getCronWeeklySchedule = (cronString) => {
  const startDate = getNextWeekStartDate();
  const schedule = getCronSchedule(cronString, startDate);
  const endMoment = moment(
    new Date(startDate).setDate(startDate.getDate() + 7)
  );
  let promoMoment = schedule.next().local();
  const promoDates = [];
  while (promoMoment < endMoment) {
    const promo = {
      day: formatMomentDay(promoMoment),
      times: [],
    };
    const currentDay = promoMoment.dayOfYear();
    const currentYear = promoMoment.year();

    while (
      currentDay === promoMoment.dayOfYear() &&
      currentYear === promoMoment.year()
    ) {
      promo.times.push(promoMoment);
      promoMoment = schedule.next().local();
    }

    promoDates.push(promo);
  }

  return promoDates;
};

const getNextWeekStartDate = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() + 7 - startDate.getDay());
  startDate.setHours(0, 0, 0, 0);
  return startDate;
};

export const mascBankAccount = (accountNumber) => {
  return '*'.repeat(accountNumber.length - 4) + accountNumber.slice(-4);
};

export const getRequiredVerificationLevel = (error) => {
  const errorCode = error.response?.data?.error?.code;

  if (!errorCode) {
    return null;
  }
  const limitErrorRegex =
    /users_ctx:kyc:(\w+)_withdrawals?_threshold_reached:required_level:(\d+)/;

  const match = errorCode.match(limitErrorRegex);

  if (!match) {
    return null;
  }

  return match[2];
};
