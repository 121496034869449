import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { formatBalance, formatFeePeriod } from '@homeplay/utils';
import Checkbox from '../UI/Checkbox';
import AmountInput from '../UI/Input/AmountInput';
import Divider from '../UI/Divider';
import Button from '../UI/Button';
import NeedVerifyAccountModal from '../Modal/NeedVerifyAccountModal';
import classes from './CashOutAmount.module.scss';

const CashOutEftAmount = ({
  balance,
  depositBalance,
  siteName,
  minAmount,
  preCheckInfo,
  onSubmitPreCheck,
  onConfirm,
  feePeriod,
  cashoutFee,
  resetPreCheck,
  cashoutFreeRequestsNum,
  isShowVerifyModal,
  setIsShowVerifyModal,
}) => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [conditionsError, setConditionsError] = useState(false);
  const [agreeConditions, setAgreeConditions] = useState(false);

  const validateAmount = (amount) => {
    if (amount.length) {
      const value = parseInt(amount.replaceAll(',', '')) * 100;
      if (value < minAmount) {
        setError(
          'Amount must be greater than or equal to ' + formatBalance(minAmount)
        );
      } else if (value > balance) {
        setError(
          'Amount must be less than or equal to ' + formatBalance(balance)
        );
      } else {
        setError(null);
      }
    }
  };

  const handleChangeAmount = (newAmount) => {
    if (newAmount !== amount) {
      resetPreCheck();
    }

    validateAmount(newAmount);
    setAmount(newAmount);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (error) {
      return;
    }

    if (preCheckInfo) {
      if (cashoutFee === 0 || agreeConditions) {
        onConfirm();
      } else {
        setConditionsError(true);
      }
    } else {
      const coinsAmount = Math.round(
        parseFloat(amount.replaceAll(',', '')) * 100
      );
      onSubmitPreCheck(coinsAmount);
      resetConditions();
    }
  };

  const resetConditions = () => {
    if (agreeConditions) {
      setAgreeConditions(false);
    }
    if (conditionsError) {
      setConditionsError(false);
    }
  };

  const withdrawDetails = () => {
    if (!preCheckInfo) {
      return '';
    }

    let details = [];

    if (preCheckInfo.fee > 0) {
      let feeInfo = '';

      if (preCheckInfo.feeRequestsCount > 0 && cashoutFreeRequestsNum > 0) {
        feeInfo += `This is your ^${getOrdinalNumber(preCheckInfo.feeRequestsCount + 1)}^ withdrawal`;
        if (feePeriod > 0) {
          feeInfo += ` within the last${formatFeePeriod(feePeriod)}`;
        }
        feeInfo += '. ';
      }

      feeInfo += `A fee of ^*${formatBalance(preCheckInfo.fee, false)}^ will be charged.`;

      details = [feeInfo];
    } else if (cashoutFee > 0) {
      details = ['No fee applied.'];
    }

    if (preCheckInfo.imPayments && preCheckInfo.imPayments.length > 1) {
      let requestsInfo = `You requested to withdraw ^${formatBalance(preCheckInfo.amount)}^ ${preCheckInfo.imPayments.length} withdrawal requests will be created: ^`;

      preCheckInfo.imPayments.forEach((request, index) => {
        requestsInfo += formatBalance(request.amount);
        if (index < preCheckInfo.imPayments.length - 1) {
          requestsInfo += ', ';
        }
      });

      requestsInfo += '^.';
      details.push(requestsInfo);
    }

    const items = details.map((textPart, index) => {
      const innerText = textPart.split('^').map((textPart, innerIndex) => {
        if (innerIndex % 2 !== 0) {
          if (textPart[0] === '*') {
            return (
              <span className="extra-bold" key={index + '' + innerIndex}>
                {textPart.slice(1)}
              </span>
            );
          }
          return <span key={index + '' + innerIndex}>{textPart}</span>;
        }
        return textPart;
      });

      return <li key={index}>{innerText}</li>;
    });

    return <ul className={classes.InfoList}>{items}</ul>;
  };

  const getOrdinalNumber = (n) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const handleChangeConditions = (event) => {
    setAgreeConditions(event.target.checked);
    if (!event.target.checked) {
      setConditionsError(true);
    }
  };

  return (
    <>
      <form name="form" onSubmit={handleSubmit} className={classes.FullHeight}>
        <div className="container">
          <div className="inline-block">
            <span className="label">Total Balance</span>
            <span className="value">
              {formatBalance(balance + depositBalance)}
            </span>
          </div>
          <div className="inline-block">
            <span className="label">Available to Withdraw *</span>
            <span className="value">{formatBalance(balance)}</span>
          </div>
          <p className={classes.BalanceInfo}>
            * Credits loaded to your account must be used to play. You can only
            withdraw what you have won.
          </p>
          <Divider />

          <label className="label" htmlFor="amount">
            Enter amount to withdraw
          </label>
          <AmountInput
            focus={true}
            id="amount"
            onChange={handleChangeAmount}
            value={amount}
            error={error}
            allowDecimal={true}
          />

          {preCheckInfo && (
            <React.Fragment>
              <div className="inline-block">
                <span className="label">You Will Withdraw</span>
                <span className="value">
                  {formatBalance(preCheckInfo.amount - preCheckInfo.fee)}
                </span>
              </div>
              <div className="inline-block">
                <span className="label">Balance after withdrawal</span>
                <span className="value">
                  {formatBalance(
                    balance + depositBalance - preCheckInfo.amount
                  )}
                </span>
              </div>

              {withdrawDetails()}

              {cashoutFee > 0 && (
                <Checkbox
                  id="agree_conditions"
                  checked={agreeConditions}
                  text="I agree to the conditions"
                  error={conditionsError}
                  errorMessage={
                    'You are required to agree with the conditions in order to continue'
                  }
                  onChange={handleChangeConditions}
                />
              )}
            </React.Fragment>
          )}
        </div>

        <div className="container bottom-block">
          {preCheckInfo ? (
            <Button
              disabled={!!error}
              fakeDisabled={cashoutFee > 0 && !agreeConditions}
              customClass="d-block"
            >
              Confirm
            </Button>
          ) : (
            <Button disabled={!!error || !amount.length} customClass="d-block">
              OK
            </Button>
          )}

          <p>
            More info about{' '}
            <Link to={`/${siteName ? siteName + '/' : ''}cashout/help`}>
              withdrawals
            </Link>
          </p>
        </div>
      </form>

      <CSSTransition
        in={isShowVerifyModal}
        timeout={0}
        classNames="modal"
        unmountOnExit
      >
        <NeedVerifyAccountModal onClose={() => setIsShowVerifyModal(false)} />
      </CSSTransition>
    </>
  );
};

export default CashOutEftAmount;
