import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser, SiteContext } from '@homeplay/app';
import classes from './MaintenanceBanner.module.scss';

export const MaintenanceBanner = () => {
  const { siteName } = useContext(SiteContext);
  const [showMaintenanceBanner, setShowMaintenanceBanner] = useState(
    !localStorage.getItem('maintenance20112024')
  );
  const user = useSelector(selectUser);

  const handleCloseMaintenanceBanner = () => {
    localStorage.setItem('maintenance20112024', '1');
    setShowMaintenanceBanner(false);
  };

  if (!showMaintenanceBanner) {
    return null;
  }

  return (
    <div className={classes.bannerWrapper}>
      <div className={classes.banner}>
        <h4>User Verification Now Live!</h4>
        <p>
          To ensure secure transactions, user verification is now required for
          withdrawals.
        </p>
        <p>
          Please complete the verification to have unlimited access for
          withdrawals.{' '}
          <Link
            to={`/${siteName ? siteName + '/' : ''}${user ? 'verification/info' : 'auth'}`}
          >
            Click here for more details.
          </Link>
        </p>
        <div className={classes.close} onClick={handleCloseMaintenanceBanner}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 17.4587L14.4567 11.986L19.9233 6.46467L17.4587 4L11.984 9.54533L6.444 4.07667L4 6.52067L9.54733 12.014L4.07667 17.556L6.52067 20L12.012 14.4547L17.5353 19.9233L20 17.4587Z"
              fill="#9B4090"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
